import { alpha, createTheme } from '@mui/material/styles'
import { FaRegIdBadge } from 'react-icons/fa'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    fontFamilySerif: string
    fontWeightThin: number
    xl: React.CSSProperties
    sectionHeading: React.CSSProperties
    sectionHeadingLarge: React.CSSProperties
    smallHeading: React.CSSProperties
    xsHeading: React.CSSProperties
    smallText: React.CSSProperties
    smallTextSerif: React.CSSProperties
    largeText: React.CSSProperties
    leadText: React.CSSProperties
    leadTextSmall: React.CSSProperties
    smallBody: React.CSSProperties
  }
  interface TypographyOptions {
    fontFamilySerif?: string
    fontWeightThin?: number
    xl?: React.CSSProperties
    sectionHeading?: React.CSSProperties
    sectionHeadingLarge?: React.CSSProperties
    smallHeading?: React.CSSProperties
    xsHeading?: React.CSSProperties
    smallText?: React.CSSProperties
    smallTextSerif?: React.CSSProperties
    largeText?: React.CSSProperties
    leadText?: React.CSSProperties
    leadTextSmall?: React.CSSProperties
    smallBody?: React.CSSProperties
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    fontFamilySerif: true
    fontWeightThin: true
    xl: true
    sectionHeading: true
    sectionHeadingLarge: true
    smallHeading: true
    xsHeading: true
    smallText: true
    smallTextSerif: true
    largeText: true
    leadText: true
    leadTextSmall: true
    smallBody: true
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    outlineDarkTrans: string
    outlineDarkTransLight: string
    outlineLightTrans: string
    outlineExtraLightTrans: string
    outlineDarkStrong: string
    footerBg: string
  }
  interface PaletteOptions {
    outlineDarkTrans?: string
    outlineDarkTransLight?: string
    outlineLightTrans?: string
    outlineExtraLightTrans?: string
    outlineDarkStrong?: string
    footerBg?: string
  }
  interface Theme {
    headerHeight: string
  }
  interface ThemeOptions {
    headerHeight: string
  }
}

const fonts = {
  sans: "'aktiv-grotesk', sans-serif",
  serif: "'ivypresto-headline', serif;",
}

const fontWeights = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const colors = {
  primary: '#aa9a85',
  primaryLight: '#f6f2eb',
  primaryDark: '#967d69',
  secondary: '#3b363d',
  footerBg: '#2b2926',
  outlineLightTrans: 'rgba(255,255,255,0.2)',
  outlineExtraLightTrans: 'rgba(255,255,255,0.1)',
}

const headingBase = {
  fontFamily: fonts.serif,
  fontWeight: fontWeights.thin,
  lineHeight: 1.2,
}

let theme = createTheme({
  spacing: 10,
  headerHeight: '96px',
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
      dark: colors.primaryDark,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: '#3b363d',
    },
    error: {
      main: '#f27373',
    },
    success: {
      main: colors.primary,
    },
    outlineDarkTrans: alpha(colors.secondary, 0.2),
    outlineDarkTransLight: alpha(colors.secondary, 0.1),
    outlineLightTrans: colors.outlineLightTrans,
    outlineExtraLightTrans: colors.outlineExtraLightTrans,
    outlineDarkStrong: colors.secondary,
    footerBg: colors.footerBg,
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: fonts.sans,
    fontFamilySerif: fonts.serif,
    htmlFontSize: 10,
    fontSize: 16,
    fontWeightThin: fontWeights.thin,
    fontWeightLight: fontWeights.light,
    fontWeightRegular: fontWeights.regular,
    fontWeightMedium: fontWeights.medium,
    fontWeightBold: fontWeights.bold,
    xl: {
      ...headingBase,
      lineHeight: 1,
    },
    h1: {
      ...headingBase,
      textTransform: 'uppercase',
      lineHeight: 1,
      letterSpacing: '0.8rem',
      fontWeight: fontWeights.thin,
    },
    h2: {
      ...headingBase,
      textTransform: 'uppercase',
      lineHeight: 1,
      letterSpacing: '0.3rem',
    },
    h3: {
      ...headingBase,
    },
    h4: {
      ...headingBase,
      fontSize: '3.2rem',
      textTransform: 'uppercase',
      letterSpacing: '0.3rem',
    },
    leadText: {
      ...headingBase,
      lineHeight: 1.3,
    },
    leadTextSmall: {
      ...headingBase,
      lineHeight: 1.3,
    },
    sectionHeading: {
      ...headingBase,
      textTransform: 'uppercase',
      letterSpacing: '0.2rem',
    },
    sectionHeadingLarge: {
      ...headingBase,
      textTransform: 'uppercase',
      letterSpacing: '0.2rem',
    },
    smallHeading: {
      ...headingBase,
      fontSize: '1.8rem',
      textTransform: 'uppercase',
      letterSpacing: '0.3rem',
      fontWeight: fontWeights.regular,
    },
    xsHeading: {
      ...headingBase,
      fontSize: '1.6rem',
      textTransform: 'uppercase',
      letterSpacing: '0.3rem',
      fontWeight: fontWeights.regular,
    },
    smallText: {
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      letterSpacing: '0.2rem',
      fontWeight: fontWeights.light,
    },
    smallTextSerif: {
      ...headingBase,
      fontSize: '1.5rem',
      textTransform: 'uppercase',
      letterSpacing: '0.3rem',
      fontWeight: fontWeights.light,
    },
    smallBody: {
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
      fontSize: '1.4rem',
    },
    largeText: {
      fontSize: '2.2rem',
      fontWeight: fontWeights.light,
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
})

theme.palette.error = {
  ...theme.palette.error,
  light: '#ba5c5a',
}

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '6.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '7.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '8.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '8.8rem',
  },
}

theme.typography.sectionHeading = {
  ...theme.typography.sectionHeading,
  fontSize: '3.6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4.1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '5.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '5.6rem',
  },
}
theme.typography.sectionHeadingLarge = {
  ...theme.typography.sectionHeadingLarge,
  fontSize: '3.6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '4.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '5.2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '6.4rem',
  },
}

theme.typography.leadText = {
  ...theme.typography.leadText,
  fontSize: '2.6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.6rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '4.0rem',
  },
}
theme.typography.leadTextSmall = {
  ...theme.typography.leadTextSmall,
  fontSize: '2.4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.2rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '3.4rem',
  },
}

theme.typography.xl = {
  ...theme.typography.xl,
  fontSize: '6rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '9rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '10rem',
  },
}

export default theme

import React, { memo } from 'react'
import {
  GlobalStyles as GlobalStylesBase,
  CssBaseline,
  useTheme,
} from '@mui/material'

const GlobalStyles = () => {
  const theme = useTheme()
  return (
    <>
      <CssBaseline />
      <GlobalStylesBase
        styles={{
          html: {
            backgroundColor: theme.palette.secondary.main,
            fontSize: '62.5%;',
            ul: {
              margin: 0,
              padding: 0,
            },
            li: {
              margin: 0,
              padding: 0,
            },
            a: {
              color: 'inherit',
              textDecoration: 'none',
              transition: theme.transitions.create(['color']),
            },
            ['a:hover']: {
              color: theme.palette.primary.main,
            },
            body: {
              backgroundColor: theme.palette.secondary.main,
              fontSize: '1.6rem',
            },
          },
        }}
      />
    </>
  )
}

export default memo(GlobalStyles)

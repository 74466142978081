exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-artist-category-landing-tsx": () => import("./../../../src/templates/ArtistCategoryLanding.tsx" /* webpackChunkName: "component---src-templates-artist-category-landing-tsx" */),
  "component---src-templates-artist-detail-tsx": () => import("./../../../src/templates/ArtistDetail.tsx" /* webpackChunkName: "component---src-templates-artist-detail-tsx" */),
  "component---src-templates-artist-landing-tsx": () => import("./../../../src/templates/ArtistLanding.tsx" /* webpackChunkName: "component---src-templates-artist-landing-tsx" */),
  "component---src-templates-artwork-category-landing-tsx": () => import("./../../../src/templates/ArtworkCategoryLanding.tsx" /* webpackChunkName: "component---src-templates-artwork-category-landing-tsx" */),
  "component---src-templates-artwork-detail-known-artist-tsx": () => import("./../../../src/templates/ArtworkDetailKnownArtist.tsx" /* webpackChunkName: "component---src-templates-artwork-detail-known-artist-tsx" */),
  "component---src-templates-artwork-detail-unknown-artist-tsx": () => import("./../../../src/templates/ArtworkDetailUnknownArtist.tsx" /* webpackChunkName: "component---src-templates-artwork-detail-unknown-artist-tsx" */),
  "component---src-templates-artwork-landing-tsx": () => import("./../../../src/templates/ArtworkLanding.tsx" /* webpackChunkName: "component---src-templates-artwork-landing-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-general-page-tsx": () => import("./../../../src/templates/GeneralPage.tsx" /* webpackChunkName: "component---src-templates-general-page-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/Homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */)
}


// npm
import React from 'react'
import { ThemeProvider } from '@mui/material'
import { AnimatePresence } from 'framer-motion'

// components
import GlobalStyles from './src/components/atoms/globalStyles/GlobalStyles'

// context
import { MessageProvider } from './src/context/MessageContext'
import { NavDrawerProvider } from './src/context/NavDrawerContext'

// styles
import theme from './src/styles/theme'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    const ss = await import('smoothscroll-polyfill')
    ss.polyfill()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <MessageProvider>
      <NavDrawerProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {element}
        </ThemeProvider>
      </NavDrawerProvider>
    </MessageProvider>
  )
}

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const transitionDelay = 0.3 * 1000 * 2
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

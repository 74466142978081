import React from 'react'

type Action =
  | {
      type: 'SET_MESSAGE'
      payload: {
        message: string
        status: 'error' | 'success' | 'warning' | 'info' | undefined
      }
    }
  | { type: 'RESET_MESSAGE' }
export type Dispatch = (action: Action) => void
type State = {
  status: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string | undefined
}
type MessageProviderProps = { children: React.ReactNode }

const MessageContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const messageReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_MESSAGE': {
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    }
    case 'RESET_MESSAGE': {
      return { ...state, message: undefined, status: undefined }
    }
  }
}

const MessageProvider = ({ children }: MessageProviderProps) => {
  const [state, dispatch] = React.useReducer(messageReducer, {
    message: undefined,
    status: undefined,
  })
  const value = { state, dispatch }
  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  )
}

const useMessage = () => {
  const context = React.useContext(MessageContext)
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider')
  }
  return context
}

export { MessageProvider, useMessage }
